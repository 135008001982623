// @flow
import { Link } from 'gatsby'
import * as React from 'react'

import { Navigation } from '@toggl/ui'

const products = {
  title: 'Meet Toggl. Four products. One mission.',
  subtitle:
    'Our tools help teams work better. No matter how or where they work.',
  items: [
    {
      image: require('components/layouts/assets/nav-toggl-track.svg').default,
      title: 'track',
      subtitle: 'Effortless time tracking',
      url: '/',
      badge: false,
    },
    {
      image: require('components/layouts/assets/nav-toggl-plan.svg').default,
      title: 'plan',
      subtitle: 'Beautifully simple team planning',
      url: 'https://toggl.com/plan/',
      badge: false,
    },
    {
      image: require('components/layouts/assets/nav-toggl-hire.svg').default,
      title: 'hire',
      subtitle: 'Easy full-cycle hiring',
      url: 'https://toggl.com/hire/',
      badge: false,
    },
    {
      image: require('components/layouts/assets/nav-toggl-work.svg').default,
      title: 'work',
      subtitle: 'Efficient expense management',
      url: '/work/',
      badge: false,
    },
  ],
}

export function GlobalProducts() {
  return (
    <Navigation.DropdownItemContents item='global-products'>
      <GlobalProductsContent />
    </Navigation.DropdownItemContents>
  )
}

export function GlobalProductsDropdown() {
  return (
    <Navigation.DropdownItem item='global-products'>
      <span>Products</span>
      <GlobalProductsContent />
    </Navigation.DropdownItem>
  )
}

function GlobalProductsContent() {
  return (
    <Navigation.MenuContent
      title={products.title}
      description={products.subtitle}
    >
      <Navigation.ProductsGroup>
        {products.items.map((item, index) => {
          return (
            <Link key={index} to={item.url}>
              <Navigation.Product
                key={item.url}
                img={item.image}
                title={item.title}
                description={item.subtitle}
                mediaWidth='284'
                mediaHeight='178'
                badge={item.badge}
              />
            </Link>
          )
        })}
      </Navigation.ProductsGroup>
    </Navigation.MenuContent>
  )
}
