module.exports = [{
      plugin: require('../../gatsby-plugin-gtm/gatsby-browser.js'),
      options: {"plugins":[],"gtmId":"GTM-P9FVGMK"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://toggl.space"},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://af6bfb6da29c4afd87a9ab6519a7bf64@o43910.ingest.sentry.io/4504089509363712","tracesSampleRate":0.00001},
    },{
      plugin: require('../../toggl/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
